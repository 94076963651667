.card-wrapper {
  padding: 1em;
  margin: 1em;
}

.small-card {
  max-width: 300px;
}

.large-card {
  max-width: 1000px;
}
