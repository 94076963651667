.home-page {
  width: 100%;
  height: 100%;
}

.home-hero-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-hero-image-banner-parent {
  width: 100%;
}

.home-hero-image-banner {
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0, .4);
  background: rgba(0, 0, 0, .4);
}

.home-hero-image-banner-text {
  width: 100%;
  color: white;
  text-align: center;
  background: transparent;
}

.home-hero-image {
  width: 100%;
  background: url("/images/pared-sur-barrels-hero.jpg") no-repeat center top;
  background-size: cover;
}

@media (min-width: 1251px) {
  .home-hero-image-banner-parent {
    padding-top: 300px;
  }

  .home-hero-image-banner-text {
    font-size: 5em;
  }

  .home-hero-image {
    height: 780px;
  }
}

@media (min-width: 1051px) and (max-width: 1250px) {
  .home-hero-image-banner-parent {
    padding-top: 220px;
  }

  .home-hero-image-banner-text {
    font-size: 4em;
  }

  .home-hero-image {
    height: 740px;
  }
}

@media (min-width: 821px) and (max-width: 1050px) {
  .home-hero-image-banner-parent {
    padding-top: 180px;
  }

  .home-hero-image-banner-text {
    font-size: 3em;
  }

  .home-hero-image {
    height: 620px;
  }
}

@media (max-width: 820px) {
  .home-hero-image-banner-parent {
    padding-top: 150px;
  }

  .home-hero-image-banner-text {
    font-size: 2em;
  }

  .home-hero-image {
    height: 500px;
  }
}
