body {
  background-color: whitesmoke;
}

.screen-container {
  padding-top: 30px;
}

.application-view-port {
  min-height: 100vh;
  min-width: 100%;
}
