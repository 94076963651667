.section-top-spacing {
  margin-top: 24px
}

.section-right-spacing {
  margin-right: 24px
}

.section-bottom-spacing {
  margin-bottom: 24px
}

.section-left-spacing {
  margin-left: 24px
}

.content-top-spacing {
  margin-top: 16px
}

.content-right-spacing {
  margin-right: 16px
}

.content-bottom-spacing {
  margin-bottom: 16px
}

.content-left-spacing {
  margin-left: 16px
}
